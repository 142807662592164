<template>
  <b-card>
    <b-row>
      <b-col lg="8" cols="12">
        <label class="d-inline-flex align-items-center">
          <b-form-input
            v-model="filterSearch"
            type="search"
            class="mx-1"
            :placeholder="`${$t('fields.search')}...`"
          ></b-form-input>
          <b-input-group prepend=">=" class="mr-1">
            <b-form-input
              v-model="filterGreaterAmount"
              type="search"
              :placeholder="$t('fields.greater_number')"
            ></b-form-input>
          </b-input-group>
          <b-input-group append="<=" class="mr-1">
            <b-form-input
              v-model="filterLessAmount"
              type="search"
              :placeholder="$t('fields.less_number')"
            ></b-form-input>
          </b-input-group>
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                ><strong> <i class="uil-calender"></i></strong
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filterTime"
              type="search"
              :placeholder="$t('fields.select_time')"
            ></b-form-input>
          </b-input-group>
          <b-button variant="primary" class="ml-2">
            {{ $t('fields.search') }}
          </b-button>
        </label>
      </b-col>
      <b-col lg="4" cols="12" class="text-right">
        <label class="d-inline-flex">
          <b-select id="selectReceivingBank" v-model="selectReceivingBank">
            <b-form-select-option :value="''"
              >{{ $t('credits.receiving_bank') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(acc, accI) in []"
              :key="accI"
              :value="acc.id"
              >{{ acc.name }}
            </b-form-select-option>
          </b-select>
          <b-select
            id="selectIssuedBank"
            v-model="selectIssuedBank"
            class="ml-2"
          >
            <b-form-select-option :value="''"
              >{{ $t('credits.issued_bank') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(issued, issuedI) in []"
              :key="issuedI"
              :value="issued.id"
              >{{ issued.name }}
            </b-form-select-option>
          </b-select>
        </label>
      </b-col>
    </b-row>
    <!-- Table -->
    <h6>
      {{ `${$t('fields.result')} (${dataList.length})` }}
    </h6>
    <b-table
      :items="dataList"
      :fields="fields"
      :busy="isFetching"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(has_deposit_amount)="data">
        <feather
          :type="data.value ? 'check-circle' : 'x-circle'"
          class="icon-dual icon-xs"
          :class="{ 'icon-dual-primary': data.value }"
        ></feather>
      </template>
      <template #cell(username)="data">
        <b-link
          :to="
            data.item.user_id
              ? `/players/detail/${data.item.user_id}`
              : `/players/list`
          "
        >
          {{ `${data.value}` }}
        </b-link>
      </template>
      <template #cell(credit)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(type)="data">
        <b-badge v-if="data.value === 'deposit'" class="badge-soft-success">
          {{ $t('credits.deposit') }}
        </b-badge>
        <b-badge
          v-else-if="data.value === 'withdraw'"
          class="badge-soft-danger"
        >
          {{ $t('credits.withdraw') }}
        </b-badge>
      </template>

      <template #cell(bank_code)="data">
        {{ $displayBankName(data.value) }}
      </template>
      <template #cell(amount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(status)="data">
        <b-badge v-if="data.value === 'processing'" variant="warning">
          {{ $t('utils.status.processing') }}
        </b-badge>
        <b-badge v-else-if="data.value === 'reject'" variant="danger">
          {{ $t('utils.status.reject') }}
        </b-badge>
        <b-badge v-else-if="data.value === 'success'" variant="success">
          {{ $t('utils.status.approve') }}
        </b-badge>
      </template>

      <template #cell(operation)="data">
        <b-button-group size="sm">
          <b-button
            variant="outline-primary"
            @click="navigateToRoute('', data.item)"
          >
            {{ $t('user.bet_report') }}
          </b-button>
          <b-button
            variant="outline-info"
            @click="navigateToRoute('', data.item)"
          >
            {{ $t('credits.credit_transaction') }}
          </b-button>
        </b-button-group>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
    <!-- pagination -->
      <PaginationInput :per-page="limit" :total="total" @update="(val) => currentPage = val"/>
  </b-card>
</template>

<script>
export default {
  name: 'RequestTransactions',
  data() {
    return {
      currentPage: 1,
      limit: 50,
      offset: 0,
      fields: [
        '#',
        {
          key: 'created_at',
          label: `${this.$t('fields.issued_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'username',
          label: `${this.$t('credits.operated_by')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'has_deposit_amount',
          label: `${this.$t('credits.has_deposit_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '90px',
          },
        },
        {
          key: 'credit',
          label: this.$t('credits.credit'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'phone_number',
          label: this.$t('user.phone'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: this.$t('fields.type'),
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'bank',
          label: this.$t('credits.issued_bank'),
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'bank_number',
          label: this.$t('bank_accounts.account_no'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'bank_code',
          label: this.$t('credits.receiving_bank'),
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'name',
          label: this.$t('bank_accounts.name'),
          thStyle: {
            minWidth: '170px',
          },
        },
        {
          key: 'amount',
          label: this.$t('fields.amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'status',
          label: this.$t('fields.status'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'detail',
          label: this.$t('fields.remark'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'operation',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
      ],
      filterSearch: '',
      filterGreaterAmount: '',
      filterLessAmount: '',
      filterTime: '',
      onSearchTimeout: null,
      selectIssuedBank: '',
      selectReceivingBank: '',
    }
  },
  computed: {
    mode() {
      return this.$route.path.split('/')[this.$route.path.split('/').length - 1]
    },
    dataList() {
      return [
        {
          id: '123',
          has_deposit_amount: true,
          type: this.mode,
          status: 'processing',
        },
        {
          id: '1234',
          has_deposit_amount: false,
          type: this.mode,
          status: 'success',
        },
        {
          id: '1234',
          has_deposit_amount: false,
          type: this.mode,
          status: 'reject',
        },
      ]
    },
    total() {
      return 0
    },
    isFetching() {
      return false
    },
  },
  watch: {
    filterPlayerCodeName() {
      this.delaySearch()
    },
    filterGreaterAmount() {
      this.delaySearch()
    },
    filterLessAmount() {
      this.delaySearch()
    },
    filterTime() {
      this.delaySearch()
    },
    selectIssuedBank(val) {
      if (val) {
        this.delaySearch()
      }
    },
    selectReceivingBank(val) {
      if (val) {
        this.delaySearch()
      }
    },
  },
  methods: {
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.$emit('fetch'), 800)
    },
    navigateToRoute(routeName) {
      if (routeName !== this.$route.name) {
        this.$router.push(routeName)
      }
    },
  },
}
</script>
